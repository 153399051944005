import type { PageParams, Pagination, User } from '@supabase/auth-js'
import type { InternalApi } from 'nitropack'
import { v4 as uuidv4 } from 'uuid'
import type { Database, Enums, Tables } from '~~/types/supabase'

type Profile = Tables<'profiles'>
type UserOptionList = Array<{ id: string; email: string; name: string }>

export const useProfiles = () => {
  const user = useSupabaseUser()
  const supabase = useSupabaseClient<Database>()
  const headers = useRequestHeaders(['cookie'])

  const profile = ref<Profile | null>(null)
  const loading = ref(false)

  const _getRetailerAdminUserOptionList = () =>
    useState<UserOptionList | null>(
      'retailer_admin_user_option_list',
      () => null
    )

  const _getUserOptionList = () =>
    useState<UserOptionList | null>('user_option_list', () => null)

  const fetchProfile = async () => {
    if (!user.value) return null

    loading.value = true

    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('user_id', user.value.id)
        .single()

      if (error) throw error

      return (profile.value = data)
    } catch (error) {
      console.error('Error fetching profile', error)

      throw error
    } finally {
      loading.value = false
    }
  }

  // Function to get all profiles
  const getProfiles = async () => {
    try {
      const [usersWithPagination, profiles] = await Promise.all([
        _doGetUsers(),
        supabase
          .from('profiles')
          .select(
            `
            *,
            user_roles(role),
            retailers!user_retailers(retailer_name)
          `
          )
          .order('full_name', { ascending: true })
          .then(({ data, error }) => {
            if (error) throw error

            return data
          })
      ])

      // Combine users and profiles based on UUID
      return profiles.map(profile => {
        const user = usersWithPagination.users.find(
          u => u.id === profile.user_id
        )
        return { ...profile, ...user }
      })
    } catch (error) {
      console.error('Error fetching profiles and users', error)

      throw error
    } finally {
      loading.value = false
    }
  }

  /**
   * Fetch a list of users to use in a select list for managing retailer group admins.
   *
   * This function will initialize the `retailer_admin_user_option_list` state if needed, creating a list of objects with the user's name,
   * email address, and ID, filtered based on the roles allowed to be in this list.
   *
   * Consumers of this function will need to adapt its payload to fit into most input elements, but the most useful data
   * to build those elements will be made available.
   */
  const getRetailerAdminUserOptionList = async () => {
    const userOptionList = _getRetailerAdminUserOptionList()

    if (userOptionList.value !== null) return userOptionList.value

    // First order of business, get the list of accessible user IDs
    const { data: availableUsers, error: userLookupError } = await supabase
      .from('user_roles')
      .select('user_id')
      .in('role', ['Retailer Group Admin', 'Site Administrator'])

    if (userLookupError) throw userLookupError

    // Just set an empty array immediately if for some crazy reason we don't have any accessible users
    if (availableUsers!.length === 0) return (userOptionList.value = [])

    const users: Awaited<{ user: User }[]> = await Promise.all(
      availableUsers.map(user => _doGetUserById(user.user_id))
    )

    return (userOptionList.value = users.map(
      user =>
        ({
          id: user.user!.id,
          email: user.user!.email!,
          name: user.user!.user_metadata!.full_name!
        }) satisfies UserOptionList[0]
    ))
  }

  /**
   * Fetch a list of users to use in a select list.
   *
   * This function will initialize the `user_option_list` state if needed, creating a list of objects with the user's name,
   * email address, and ID; automatically iterating over the paginated result set from the Auth Admin API.
   *
   * Consumers of this function will need to adapt its payload to fit into most input elements, but the most useful data
   * to build those elements will be made available.
   */
  const getUserOptionList = async () => {
    const userOptionList = _getUserOptionList()

    if (userOptionList.value !== null) return userOptionList.value

    const userOptions: UserOptionList = []
    let hasAnotherPage = true
    let page = 1

    while (hasAnotherPage) {
      const usersWithPagination = await _doGetUsers({ page })

      usersWithPagination.users.forEach(user => {
        userOptions.push({
          id: user.id,
          email: user.email!,
          name: user.user_metadata.full_name
        })
      })

      page++
      hasAnotherPage = usersWithPagination.nextPage !== null
    }

    return (userOptionList.value = userOptions)
  }

  const _doGetProfileWithRetailersById = async (userId: string) => {
    const { data, error } = await supabase
      .from('profiles')
      .select(
        `
        *,
        retailers!user_retailers(id, retailer_name)
      `
      )
      .eq('user_id', userId)
      .single()

    if (error) throw error

    return data
  }

  const _doGetRoleById = async (userId: string) => {
    const { data, error } = await supabase
      .from('user_roles')
      .select('*')
      .eq('user_id', userId)
      .single()

    if (error) throw error

    return data.role
  }

  const _doGetUserById = async (userId: string) => {
    return $fetch<InternalApi['/api/users/:uuid']['get']>(
      `/api/users/${userId}`,
      {
        headers,
        method: 'GET'
      }
    )
  }

  const _doGetUsers = async (params?: PageParams) => {
    return $fetch<{ users: User[]; aud: string } & Pagination>('/api/users', {
      headers,
      method: 'GET',
      query: params
    })
  }

  const getProfileByUserId = async (userId: string) => {
    loading.value = true

    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('user_id', userId)
        .single()

      if (error) throw error

      return data
    } catch (error) {
      console.error(`Error fetching profile for user ID ${userId}`, error)

      throw error
    } finally {
      loading.value = false
    }
  }

  const getRoleByUserId = async (userId: string) => {
    loading.value = true

    try {
      return await _doGetRoleById(userId)
    } catch (error) {
      console.error(`Error fetching role for user ID ${userId}`, error)

      throw error
    } finally {
      loading.value = false
    }
  }

  /**
   * Retrieve the information to manage the user profile for the specified user ID.
   *
   * This function is optimized specifically for the manage user page and not intended for general re-use.
   */
  const getUserProfileAndRoleByUserId = async (userId: string) => {
    loading.value = true

    try {
      const results = await Promise.all([
        _doGetUserById(userId),
        _doGetProfileWithRetailersById(userId),
        _doGetRoleById(userId)
      ])

      return { profile: results[1], user: results[0].user, role: results[2] }
    } catch (error) {
      console.error(`Error fetching user profile for user ID ${userId}`, error)

      throw error
    } finally {
      loading.value = false
    }
  }

  const createProfile = async (email: string, data?: object) => {
    loading.value = true

    try {
      const response = await $fetch('/api/users/invite-user', {
        method: 'POST',
        body: { email, ...(data ?? {}) }
      })

      return response.user
    } catch (error) {
      console.error('Error inviting user', error)

      throw error
    } finally {
      loading.value = false
    }
  }

  const deleteUserById = async (userId: string) => {
    loading.value = true

    try {
      await $fetch<InternalApi['/api/users/:uuid']['delete']>(
        `/api/users/${userId}`,
        {
          method: 'DELETE'
        }
      )
    } catch (error) {
      console.error('Error deleting user', error)

      throw error
    } finally {
      loading.value = false
    }
  }

  /**
   * Updates the password for the currently authenticated user.
   */
  const updateCurrentUserPassword = async (password: string) => {
    try {
      const { error } = await supabase.auth.updateUser({
        password
      })

      if (error) throw error
    } catch (error) {
      console.error('Error updating user', error)

      throw error
    }
  }

  /**
   * Updates the profile for the currently authenticated user.
   *
   * This function makes use of the `supabase.auth` APIs which allow the user to make updates to their own account,
   * other functions should be used to support "admin updates a user's account" workflows.
   */
  const updateCurrentUserProfile = async (
    name: string,
    enableEmailNotifications: boolean
  ) => {
    try {
      const { error: updateUserError } = await supabase.auth.updateUser({
        data: { full_name: name }
      })

      if (updateUserError) throw updateUserError

      // After updating the user's profile, reset the local state
      const { data, error: updateProfileError } = await supabase
        .from('profiles')
        .update({
          full_name: name,
          email_notifications_enabled: enableEmailNotifications
        })
        .eq('user_id', user.value?.id ?? '')
        .select()
        .single()

      if (updateProfileError) throw updateProfileError

      profile.value = data
    } catch (error) {
      console.error('Error updating user', error)

      throw error
    }
  }

  const updateUserAndProfileByUserId = async (userId: string, data: object) => {
    loading.value = true

    try {
      await $fetch(`/api/users/${userId}`, {
        method: 'POST',
        body: data
      })
    } catch (error) {
      console.error('Error updating user', error)

      throw error
    } finally {
      loading.value = false
    }
  }

  const uploadAvatar = async (file: File, userId: string) => {
    try {
      // Store avatars in a folder using the user ID and a randomly generated filename, this improves compatibility with Supabase's RLS features
      const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1)
      const fileName = `${userId}/${uuidv4()}.${fileExt}`

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(fileName, file, { upsert: true })

      if (uploadError) throw uploadError

      const {
        data: { publicUrl }
      } = supabase.storage.from('avatars').getPublicUrl(fileName)

      const { error: updateError } = await supabase
        .from('profiles')
        .update({ avatar_url: publicUrl })
        .eq('user_id', userId)

      if (updateError) throw updateError

      return publicUrl
    } catch (error) {
      console.error('Error uploading avatar', error)

      throw error
    }
  }

  const updateUserRole = async (userId: string, role: Enums<'app_role'>) => {
    loading.value = true

    try {
      const { error } = await supabase
        .from('user_roles')
        .update({ role })
        .eq('user_id', userId)

      if (error) throw error
    } catch (error) {
      console.error(`Error updating role for user ID ${userId}`, error)

      throw error
    } finally {
      loading.value = false
    }
  }

  return {
    profile,
    loading,
    createProfile,
    deleteUserById,
    fetchProfile,
    getProfileByUserId,
    getProfiles,
    getRoleByUserId,
    getRetailerAdminUserOptionList,
    getUserOptionList,
    getUserProfileAndRoleByUserId,
    updateCurrentUserPassword,
    updateCurrentUserProfile,
    updateUserAndProfileByUserId,
    updateUserRole,
    uploadAvatar
  }
}
